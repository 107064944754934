import { graphql, StaticQuery } from "gatsby";
import React from "react";
import { Responsive } from "../utils/Responsive";
import { RichText } from "./RichText";
import { Body } from "./Typography";

const ColumnedText = ({ inputId }) => {
    return (
        <StaticQuery
            query={graphql`
                query AllColumnedTextQuery {
                    allContentfulColumnedText {
                        edges {
                            node {
                                contentful_id
                                column1 {
                                    raw
                                }
                                column2 {
                                    raw
                                }
                            }
                        }
                    }
                }
            `}
            render={(data) => {
                const allColumnedText = data.allContentfulColumnedText.edges.map(
                    ({ node }) => node
                );

                const columnedText = allColumnedText.filter(
                    (columnedText) => columnedText.contentful_id === inputId
                )[0];

                const { column1, column2 } = columnedText;

                return (
                    <Responsive
                        sm={
                            <Body>
                                <RichText>{column1}</RichText>
                                <RichText>{column2}</RichText>
                            </Body>
                        }
                        md={
                            <div
                                style={{
                                    display: "grid",
                                    gridGap: 16,
                                    gridTemplateColumns: "1fr 1fr",
                                }}
                            >
                                <Body>
                                    <RichText>{column1}</RichText>
                                </Body>
                                <Body>
                                    <RichText>{column2}</RichText>
                                </Body>
                            </div>
                        }
                    />
                );
            }}
        />
    );
};

export { ColumnedText };
