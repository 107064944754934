import styled from "@emotion/styled";
import { useLocation } from "@reach/router";
import { Link } from "gatsby";
import React from "react";
import tw from "twin.macro";
import { Responsive } from "../utils/Responsive";
import { theme } from "../utils/theme";
import { Logo } from "./Logo";
import { Space } from "./Space";
import { A } from "./Typography";

const Header = () => {
    return <Responsive sm={<MobileHeader />} md={<DesktopHeader />} />;
};

export { Header };

const MobileHeader = () => {
    return (
        <MobileStyledHeader size="none">
            <div tw="grid relative w-full">
                <Link to="/">
                    <Logo />
                </Link>
            </div>
        </MobileStyledHeader>
    );
};

const DesktopHeader = () => {
    const { pathname } = useLocation();

    const isFullHeader = pathname === "/" || pathname === "/discover";

    const isInfoHeader =
        pathname.includes("/about") ||
        pathname.includes("faq") ||
        pathname.includes("made-to-measure") ||
        pathname.includes("shipping-and-returns") ||
        pathname.includes("terms-and-conditions");

    if (isFullHeader)
        return (
            <div tw="grid fixed w-full p-8 z-30">
                <Link to="/">
                    <Logo />
                </Link>
            </div>
        );

    if (isInfoHeader)
        return (
            <StyledHeader>
                <div tw="grid" style={{ gridTemplateColumns: "4fr 1fr" }}>
                    <Logo />
                </div>
                <InfoNav />
                <div />
            </StyledHeader>
        );

    return (
        <StyledHeader>
            <div tw="grid" style={{ gridTemplateColumns: "4fr 1fr" }}>
                <Logo />
            </div>
        </StyledHeader>
    );
};

const InfoNav = () => {
    const { pathname } = useLocation();
    return (
        <Space.H tw="items-center">
            <MenuLink
                to="/about"
                text="About Us"
                active={pathname.includes("about")}
            />
            <MenuLink
                to="/made-to-measure"
                text="Made to Measure"
                active={pathname.includes("made-to-measure")}
            />
            <MenuLink
                to="/terms-and-conditions"
                text="Terms and Conditions"
                active={pathname.includes("terms-and-conditions")}
            />
        </Space.H>
    );
};

interface MenuLinkProps {
    to: string;
    text: string;
    active?: boolean;
}

const MenuLink: React.FC<MenuLinkProps> = ({ to, text, active }) => {
    return (
        <Link to={to}>
            {active ? (
                <A as="span" tw="opacity-100">
                    {text}
                </A>
            ) : (
                <A as="span">{text}</A>
            )}
        </Link>
    );
};

const MobileStyledHeader = styled(Space.V)`
    ${tw`z-30 items-center fixed p-8`};
`;

const StyledHeader = styled.div`
    ${tw`fixed w-full grid p-5 z-10 z-50`};
    background: ${theme.colors.paleGray};
    grid-template-columns: 1fr 2fr 1fr;
`;
