import { useEffect, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";

export type AppointmentType = {
    start: Date;
};

const GOOGLE_CALENDAR_ID = "hello@openweaves.com";
const GOOGLE_CALENDAR_API_KEY = "AIzaSyAz3iGDIolLhAmk48ryIEMHLxIzzWQAQGg";

const GOOGLE_CALENDAR_URL = `https://www.googleapis.com/calendar/v3/calendars/${GOOGLE_CALENDAR_ID}/events?key=${GOOGLE_CALENDAR_API_KEY}`;

export const useAppointmentEvents = () => {
    const [appointments, setAppointments] = useState<AppointmentType[]>([]);

    useEffect(() => {
        const getAppointments = async () => {
            const res = await axios.get(GOOGLE_CALENDAR_URL);

            if (res.status === 200) {
                const events: AppointmentType[] = res.data.items.map(
                    (appointment) => {
                        const start = new Date(
                            appointment.start?.dateTime?.toString()
                        );
                        return {
                            start,
                        };
                    }
                );

                const orderedAppointments = events.sort(
                    (e1, e2) =>
                        dayjs(e1?.start).valueOf() - dayjs(e2?.start).valueOf()
                );

                setAppointments(orderedAppointments);
            } else {
                console.log(res.status);
            }
        };

        getAppointments();
    }, []);

    return { appointments };
};
