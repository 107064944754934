import styled from "@emotion/styled";
import tw from "twin.macro";

const V = styled.div<{ size?: "none" | "xs" | "sm" | "lg" | "xl" }>`
    ${tw`flex flex-col items-start w-full`};
    ${({ size }) => {
        switch (size) {
            case "none":
                return tw``;
            case "xs":
                return tw`space-y-1`;
            case "sm":
                return tw`space-y-2`;
            case "lg":
                return tw`space-y-8`;
            case "xl":
                return tw`space-y-16`;
            default:
                return tw`space-y-4`;
        }
    }};
`;

const H = styled.div<{ size?: "none" | "xs" | "sm" | "lg" | "xl" }>`
    ${tw`flex items-start w-full`};
    ${({ size }) => {
        switch (size) {
            case "none":
                return tw``;
            case "xs":
                return tw`space-x-1`;
            case "sm":
                return tw`space-x-2`;
            case "lg":
                return tw`space-x-8`;
            case "xl":
                return tw`space-x-16`;
            default:
                return tw`space-x-4`;
        }
    }}
`;

const Space = { V, H };

export { Space };
