import { Link } from "gatsby";
import React from "react";
import { useRecoilValue } from "recoil";
import { whiteUiState } from "../state/atoms";
import { theme } from "../utils/theme";
import { LogoIcon } from "./icons/LogoIcon";

import { Navlink } from "./Typography";

const Logo = () => {
    const isWhite = useRecoilValue(whiteUiState);
    return (
        <Navlink as={Link} to="/">
            <LogoIcon fill={isWhite ? "white" : theme.colors.black} />
        </Navlink>
    );
};

export { Logo };
