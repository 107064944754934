import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import React from "react";
import { ColumnedText } from "./ColumnedText";
import { Body } from "./Typography";

const options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => (
            <>
                <Body>{children}</Body>
                <br />
            </>
        ),
        [BLOCKS.HEADING_3]: (node, children) => (
            <div tw="uppercase" style={{ marginTop: 20, marginBottom: 20 }}>
                <Body>{children}</Body>
                <br />
            </div>
        ),
        [INLINES.EMBEDDED_ENTRY]: (props) => {
            const id = props.data.target.sys.id;
            return <ColumnedText inputId={id} />;
        },
    },
};

const RichText = ({ children }) => {
    return <>{renderRichText(children, options)}</>;
};

export { RichText };
