import { useRecoilState } from "recoil";
import { isBookAppointmentOpenState } from "../../../state/atoms";

const useBookAppointment = () => {
    const [isBookAppointmentOpen, setBookAppointmentOpen] = useRecoilState(
        isBookAppointmentOpenState
    );

    const openBookAppointment = () => setBookAppointmentOpen(true);
    const closeBookAppointment = () => setBookAppointmentOpen(false);

    return {
        isBookAppointmentOpen,
        openBookAppointment,
        closeBookAppointment,
    };
};

export { useBookAppointment };
