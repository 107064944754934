import React from "react";

const LogoIcon = ({ ...props }) => {
    return (
        <svg {...props} viewBox="0 0 540 53">
            <g clip-path="url(#clip0)">
                <path d="M504.64 35.48C507.76 42.75 514.47 46.51 521.34 46.51C528.13 46.51 533.25 43.63 533.25 37.8C533.25 33.25 530.13 31.41 524.78 30.13L514.79 27.73C508.16 26.21 502.73 22.46 502.73 14.54C502.72 5.99 509.59 0 519.5 0C528.53 0 534.92 4.24 538.04 9.91L533.57 14.78C531.25 9.51 526.22 5.75 519.51 5.75C513.36 5.75 509.28 8.79 509.28 14.22C509.28 18.94 512.64 20.53 517.83 21.73L526.7 23.81C534.05 25.57 539.73 29 539.73 37.63C539.73 46.82 531.74 52.25 521.35 52.25C512.16 52.25 503.74 48.1 500.21 40.33L504.64 35.48Z" />
                <path d="M493.48 51.3H460.16V0.959999H492.76V6.87H467.03V22.21H485.33V28.2H467.03V45.38H493.48V51.3Z" />
                <path d="M425.1 51.3L408.24 0.959999H415.75L429.33 43.07H429.65L443.07 0.959999H450.26L433.41 51.3H425.1Z" />
                <path d="M394.21 36.6H375.83L371.12 51.3H364.25L381.03 0.959999H389.5L406.28 51.3H399L394.21 36.6ZM377.67 31H392.45L385.18 8.63H384.86L377.67 31Z" />
                <path d="M357.26 51.3H323.94V0.959999H356.54V6.87H330.81V22.21H349.11V28.2H330.81V45.38H357.26V51.3Z" />
                <path d="M304.55 42.35H305.03L309.67 0.959999H315.37L308.8 51.3H300.57L293.38 10.17H292.9L285.55 51.3H277.32L270.85 0.959999H276.95L281.59 42.19H281.99L289.34 0.959999H297.05L304.55 42.35Z" />
                <path d="M170.58 39.96V0.959999H177.05V51.3H170.9L145.97 9.75V51.3H139.5V0.959999H147.65L170.58 39.96Z" />
                <path d="M130.3 51.3H96.98V0.959999H129.58V6.87H103.85V22.21H122.15V28.2H103.85V45.38H130.3V51.3Z" />
                <path d="M57.76 31.17V51.3H50.8V0.959999H68.06C80.53 0.959999 87.64 6.63 87.64 16.06C87.64 25.49 80.53 31.16 68.06 31.16H57.76V31.17ZM67.9 25.33C76.53 25.33 80.76 22.29 80.76 16.06C80.76 9.83 76.54 6.79 67.9 6.79H57.76V25.33H67.9Z" />
                <path d="M20.94 52.26C8.79 52.26 0 41.63 0 26.13C0 10.63 8.79 0 20.94 0C33.09 0 41.88 10.63 41.88 26.13C41.87 41.63 33.08 52.26 20.94 52.26ZM20.94 6.07C12.63 6.07 7.19 13.82 7.19 26.13C7.19 38.44 12.62 46.19 20.94 46.19C29.25 46.19 34.69 38.44 34.69 26.13C34.68 13.82 29.25 6.07 20.94 6.07Z" />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="539.72" height="52.26" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export { LogoIcon };
